<template>
  <div class="container">
    <h4 class="text-center my-4">Список студентов</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <DataTable :value="studentAgreement_form.students" showGridlines stripedRows
                 :globalFilterFields="['barcode', 'lastname','firstname']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
            <span class="p-input-icon-left">
              <i class="pi pi-search"/>
              <InputText v-model="filters['global'].value" placeholder="Поиск студента"/>
            </span>
          </div>
        </template>

        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{index+1}}
          </template>
        </Column>
        <Column field="barcode" header="Баркод"></Column>

        <Column header="ФИО">
          <template #body="{data}">
            {{data.lastname}} {{data.firstname}}
          </template>
        </Column>

        <Column header="Доп. соглашение">
          <template #body="{data}">
            <div v-if="data.file_url == null">
              <Button label="Соглашение" icon="pi pi-external-link" @click="openAgreementDialog(data.id)"/>
            </div>
            <div v-else>
              <a :href="'https://back.uib.kz/documents_uploads/' + data.file_url" class="mx-1">
                Файл
              </a>
            </div>

          </template>
        </Column>


      </DataTable>




      <Dialog header="Соглашение" v-model:visible="displayAgreement" :style="{width: '100%', maxWidth: '700px'}">




        <div class="my-2">
          <div class="row mb-2">
            <div class="col-md-5">
              Дата:
            </div>
            <div class="col-md-7">
              <input type="date" class="form-control" placeholder="Дата"
                     @change="changeAdditionalContractData('date', $event)">

            </div>

          </div>

          <div class="row border rounded-3 p-2 my-2">
            <div class="col-md-4">Доп. соглашение (смена языка)</div>
            <div class="col-md-4">
              <div>
<!--                <a class="text-decoration-none" target="_blank"-->
<!--                   :href="`${commonData.uploadsPath()}${contracts[prop]}`">-->
<!--                  <i class="pi pi-file"></i>-->
<!--                  Файл-->
<!--                </a>-->
              </div>
            </div>
            <div class="col-md-4 text-center">
              <Button
                      icon="pi pi-plus"
                      label="Создать"
                      :loading="loadingAgreement[1]"
                      @click="save(1)"/>
            </div>
          </div>


          <div class="row border rounded-3 p-2 my-2">
            <div class="col-md-4">Доп. соглашение (смена ОП)</div>
            <div class="col-md-4">
              <div>
<!--                <a class="text-decoration-none" target="_blank"-->
<!--                   :href="`${commonData.uploadsPath()}${contracts[prop]}`">-->
<!--                  <i class="pi pi-file"></i>-->
<!--                  Файл-->
<!--                </a>-->
              </div>
            </div>
            <div class="col-md-4 text-center">

              <Button icon="pi pi-plus"
                      label="Создать"
                      :loading="loadingAgreement[2]"
                      @click="save(2)"/>
            </div>
          </div>


        </div>


        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" @click="closeAgreementDialog" class="p-button-text"/>
        </template>
      </Dialog>

    </div>





  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {FilterMatchMode, FilterOperator} from "primevue/api";
import {convertDateToTimestamp} from "@/utils/helpers/date.helpers";

export default {
  name: 'EducationProgramChangeStudents',
  data() {
    return {
      loading: true,

      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'barcode': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'lastname': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'firstname': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },

      // students: [
      //   {
      //     id: 1,
      //     barcode: 2112021,
      //     lastname: 'Иванов',
      //     firstname: 'Иван',
      //   }
      // ],
      displayAgreement: false,
      loadingAgreement: {},
      selected_student: null,
      additionalContract: {}

    }
  },
  computed: {
    ...mapState('studentAgreement', ['studentAgreement_form'])
  },

  methods: {

    ...mapActions('studentAgreement', ['GET_EDUCATION_PROGRAM_CHANGE_STUDENTS','POST_STUDENT_AGREEMENT']),


    //...mapMutations('userinfo', ['SET_USER_ID']),

    openAgreementDialog(student_id) {
      this.displayAgreement = true
      this.selected_student = student_id
    },
    closeAgreementDialog() {
      this.displayAgreement = false
    },

    changeAdditionalContractData(property, e, val = 'value') {
      let value = e.target[val]
      this.additionalContract[property] = parseInt((new Date(value).getTime() / 1000).toFixed(0))

      console.log(this.additionalContract, 'additionalContract change')
    },

    async save(type) {
      this.displayAgreement = false
      this.loadingAgreement[type] = true
      this.additionalContract['student_id'] = this.selected_student
      this.additionalContract['contract_type'] = type


      const res = await this.POST_STUDENT_AGREEMENT(this.additionalContract)
      if (res) {
        await this.GET_EDUCATION_PROGRAM_CHANGE_STUDENTS()
        this.$message({title: 'Контракт успешно создан'})
      }


      this.loadingAgreement[type] = false
    }

  },
  async mounted() {
    await this.GET_EDUCATION_PROGRAM_CHANGE_STUDENTS()

    this.loading = false
  },
}

</script>